<template>
    <div>
        <div class="page">
            <div class="page_header">
                <el-breadcrumb separator="/">
                    <el-breadcrumb-item>关键词</el-breadcrumb-item>
                    <el-breadcrumb-item>列表</el-breadcrumb-item>
                </el-breadcrumb>
            </div>

            <div class="page_operate">
                <!-- <div class="page_search">
                    <div style="flex: 1">
                        <input type="text" v-model="inquireValue" @keyup.enter="handleEnter" placeholder="请输入关键词" />
                    </div>
                    <div style="flex: 2">
                        <button class="button" @click="onInquireInfo">搜索</button>
                    </div>

                </div> -->
                <vy-search-box v-model="inquireValue" :btn-click="onInquireInfo" placeholder="请输入关键词"></vy-search-box>
                <div class="page_handleBtn">
                    <button class="button" @click="chooseAddModuleClick">添加关键词</button>
                    <button class="button" @click="batchDeleteClick">批量删除</button>
                </div>
            </div>

            <div class="page_content">
                <el-table
                    ref="multipleTable"
                    tooltip-effect="dark"
                    :data="tableData"
                    border
                    style="width: 100%"
                    @selection-change="handleSelectionChange"
                >
                    <el-table-column type="selection" align="right" width="55"> </el-table-column>
                    <el-table-column prop="key_word" align="center" width="420" label="关键词">
                        <template slot-scope="{ row }">
                            <div class="key_word">{{ row.key_word }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="cate_name" align="center" label="分类"> </el-table-column>
                    <el-table-column prop="type" label="类型" align="center">
                        <template slot-scope="{ row }">
                            {{ row.type == 1 ? "共享" : row.type == 2 ? "搜索" : row.type == 3 ? "底部" : "" }}
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="操作">
                        <template slot-scope="scope">
                            <div class="content_operate">
                                <button class="operate_button1" @click="amendById(scope.row.id, scope.row.key_word)">编辑</button>
                                <button class="operate_button2" @click="deleteInfoById(scope.row.id)">删除</button>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>

        <amend v-if="isAmend" :amendId="amendId" :amendTitle="amendTitle" @closePage="closePageClick"></amend>
    </div>
</template>
<script>
import axios from "axios";
import qs from "qs";
import VySearchBox from "@/components/customs/VySearchBox.vue";

export default {
    components: {
        amend: () => import("./amend.vue"),
        VySearchBox,
    },
    data() {
        return {
            inquireValue: "",

            isAmend: 0,
            amendId: null,
            amendTitle: "",
        };
    },
    mounted() {
        this.getPageInfo();
    },
    computed: {
        tableData() {
            return this.$store.state.keywordModule.paging.data;
        },
        refreshPage() {
            return this.$store.state.chooseModule.value;
        },
    },
    watch: {
        refreshPage() {
            this.getPageInfo();
        },
    },
    methods: {
        // 获取全部数据
        getPageInfo() {
            const payload = {};
            payload.search = this.inquireValue;
            this.$store.dispatch("keywordModule/getPagingInfo", payload);
        },

        // 分页 -- 当前页改变传值
        currentChange(val) {
            this.getPageInfo();
        },

        // 回车
        handleEnter() {
            this.onInquireInfo();
        },

        // 搜索
        onInquireInfo() {
            this.getPageInfo();
        },

        // 编辑
        amendById(id, title) {
            this.isAmend = 1;
            this.amendId = id;
            this.amendTitle = title;
        },
        // 关闭编辑弹窗
        closePageClick(value) {
            this.isAmend = value;
            this.getPageInfo();
        },

        // 删除
        deleteInfoById(id) {
            this.$confirm("是否删除?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                axios
                    .post(
                        "/api/key_word/del",
                        qs.stringify({
                            id,
                        })
                    )
                    .then(() => {
                        this.$message({
                            type: "success",
                            message: "删除成功",
                        });
                        this.getPageInfo();
                    });
            });
        },

        // 多选
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },

        // 批量删除
        batchDeleteClick() {
            const idArr = this.multipleSelection.map(item => {
                return item.id;
            });
            if (idArr.length == 0) {
                this.$message({
                    type: "info",
                    message: "请选择你需要删除的数据",
                });
            } else {
                this.$confirm("是否删除?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }).then(() => {
                    axios
                        .post(
                            "/api/key_word/dele",
                            qs.stringify({
                                id: idArr,
                            })
                        )
                        .then(() => {
                            this.$message({
                                type: "success",
                                message: "删除成功",
                            });
                            this.getPageInfo();
                        });
                });
            }
        },

        // 进入添加模块
        chooseAddModuleClick() {
            this.$store.commit("chooseModule/onChoose", "add");
        },
    },
};
</script>
<style scoped lang="less">
.page {
    .page_operate {
        margin: 20px 0 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .page_search {
            display: flex;
            align-items: center;

            div:nth-child(1) {
                margin-right: 5px;
            }

            div:nth-child(2) {
                margin-right: 10px;
            }

            /deep/ .el-input__inner {
                border-radius: 20px;
            }

            input {
                height: 40px;
                padding-left: 15px;
                border: 1px solid #ebedf2;
                box-sizing: border-box;
                background-color: #fff;
                border-radius: 20px;

                &:focus {
                    outline: 1px solid skyblue;
                }
            }
        }

        .page_handleBtn {
            button {
                margin-right: 10px;

                &:nth-child(2) {
                    background: linear-gradient(to right, #f38ca8, #fe7096);
                }
            }
        }
    }

    .page_content {
        margin-top: 10px;
        .el-table {
            border-radius: 20px;
        }

        .content_img {
            width: 120px;
            height: 100px;
            object-fit: cover;
        }

        .content_status,
        .content_btn {
            width: 54px;
            height: 33px;
            line-height: 33px;
            color: #fff;
            margin: 0 auto;
            border-radius: 20px;
        }

        .content_operate {
            width: 100%;
            display: flex;
            justify-content: space-around;
            align-items: center;
            flex-wrap: wrap;

            button {
                width: 92px;
                height: 36px;
                border-radius: 16px;
                border: 0;
                font-size: 14px;
                letter-spacing: 2px;
                color: #fff;
                margin: 5px 0;
                cursor: pointer;
            }

            .operate_button1 {
                background: linear-gradient(to right, #da8cff, #9a55ff);
            }

            .operate_button2 {
                background: linear-gradient(to right, #f38ca8, #fe7096);
            }

            .operate_button3 {
                background: linear-gradient(to right, #84d9d2, #07cdae);
            }
            .operate_button4 {
                // background: linear-gradient(to left, #16222a, #3a6073);
                background: linear-gradient(to right, #ffbf96, #fe7096);
            }
        }
    }
    .key_word {
        // width: 160px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        text-align: left;
    }
}
</style>
